<template>
  <div>
    <util-date-type-picker />
    <util-date-range-picker />

    <div class="cui__utils__heading">
      <strong>User Participation Summary</strong>
      <div class="text-right mb-1">
        <download-json-csv :data="csvItems" :name="csvFileName" :labels="csvHeader">
          <!-- :data="items"/filteredItems :fields="csvFieldsToInclude" -->
          <b-button variant="primary" size="md" type="submit">Download Report</b-button>
        </download-json-csv>
      </div>
    </div>

    <b-card no-body>
      <!-- User Interface controls -->
      <b-card-header class="bg-light">
        <b-row>
          <b-col md="8" xl="5">
            <b-input-group>
              <b-form-input v-model="filter" class="form-control" placeholder="Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col md="4" xl="2" class="offset-xl-5">
            <b-form-group label-cols-sm="4" label-cols-xl="6" label="Per page" class="mb-0">
              <b-form-select v-model="perPage" :options="pageOptions" class="form-control"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <!-- Main table element -->
      <b-table
        show-empty
        stacked="md"
        :items="items"
        :fields="fields"
        :filter-included-fields="filterIncludedFields"
        :filter-ignored-fields="filterIgnoredFields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template slot="name" slot-scope="row">{{ row.value.first }} {{ row.value.last }}</template>

        <template slot="isActive" slot-scope="row">{{ row.value ? 'Yes :)' : 'No :(' }}</template>

        <template slot="actions" slot-scope="row">
          <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >Info modal</b-button>
          <b-button
            size="sm"
            @click="row.toggleDetails"
          >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            class="my-0"
          ></b-pagination>
      </b-card-footer>
    </b-card>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>

  </div>
</template>

<script>
import DateTypePicker from './DateTypePicker'
import DateRangePicker from './DateRangePickerHelper'
import JsonCSV from 'vue-json-csv'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'UserParticipation', // TableBootstrapComplete
  components: {
    'util-date-type-picker': DateTypePicker,
    'util-date-range-picker': DateRangePicker,
    'download-json-csv': JsonCSV,
  },
  data() {
    return {
      items: [],
      fields: [
        // If you dont want to display - set thClass & tdClass as d-none
        // Control searchable or not by using filterIncludedFields and filterIgnoredFields
        { key: 'senderProfile', label: 'Sender Profile Name', sortable: true },
        { key: 'orderNo', label: 'Order #', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'orderDate', label: 'Order Date', sortable: true, sortDirection: 'desc' },
        { key: 'reqShipDate', label: 'Requested Mail Date', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'shipDate', label: 'Mail Date', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'email', label: 'Sender Email', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'senderName', label: 'Sender Name', sortable: true },
        // { key: 'senderFirstName', label: 'Sender First Name', sortable: true },
        // { key: 'senderLastName', label: 'Sender Last Name', sortable: true },
        { key: 'supervisorId', label: 'Manager ID', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'employeeId', label: 'Employee ID', sortable: true },
        { key: 'rFirstName', label: 'Recipient First Name', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'rLastName', label: 'Recipient Last Name', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'custAccNo', label: 'Customer Account Number', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'catName', label: 'Category', sortable: true },
        { key: 'prodName', label: 'Card Title', sortable: true },
        { key: 'sentiment', label: 'Sentiment', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'message', label: 'Personal Message', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'signature', label: 'Signature', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'rAddress1', label: 'Recipient Address1', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'rAddress2', label: 'Recipient Address2', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'rCity', label: 'Recipient City', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'rState', label: 'Recipient State', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'rZip', label: 'Recipient ZipCode', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'location', label: 'Location', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      ],
      filterIncludedFields: ['senderProfile', 'orderDate', 'senderName', 'employeeId', 'catName', 'prodName'],
      filterIgnoredFields: [], // Included overrides ignored if specified in both
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // download-json-csv
      csvItems: [],
      csvFileName: 'UserParticipation.csv',
      csvHeader: {
        senderProfile: 'Sender Profile Name',
        orderNo: 'Order #',
        orderDate: 'Order Date',
        reqShipDate: 'Requested Mail Date',
        shipDate: 'Mail Date',
        email: 'Sender Email',
        senderName: 'Sender Name',
        // senderFirstName: 'Sender First Name',
        // senderLastName: 'Sender Last Name',
        supervisorId: 'Manager ID',
        employeeId: 'Employee ID',
        rFirstName: 'Recipient First Name',
        rLastName: 'Recipient Last Name',
        custAccNo: 'Customer Account Number',
        catName: 'Category',
        prodName: 'Card Title',
        sentiment: 'Sentiment',
        message: 'Personal Message',
        signature: 'Signature',
        rAddress1: 'Recipient Address1',
        rAddress2: 'Recipient Address2',
        rCity: 'Recipient City',
        rState: 'Recipient State',
        rZip: 'Recipient ZipCode',
        location: 'Location',
      },
      // csvFieldsToInclude: ['orderDate', 'userName', 'prodName', 'qty'],
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
      'globalDateRange',
      'globalClientSelected',
      'globalDateTypeSelected',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },

  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.csvItems = filteredItems // Adding this to update the download json csv fields
    },
    updateChart(details) {
      this.items = details
      this.totalRows = details.length
      this.csvItems = details
    },

    triggerUpdateChart() {
      this.startLoading()
      const selectFilter = '(count,total,hits.(data.(order_no,creation_date,c_shippedDate,' +
        'c_senderProfile,c_supervisorID,c_employeeID,c_location,c_customerAccountNo,' +
        'customer_info.(customer_name,email),' +
        'product_items.(product_name,c_productCategory,c_sentiment,c_hbc_personalizationMessage,c_signature),' +
        'shipments.(c_req_ship_date,shipping_address.(**)))))'
      this.fetchOrderedProductDetails({
        saveToStateObj: 'setOrderedProductsDetails',
        selectFilter: selectFilter,
        dateType: this.globalDateTypeSelected,
        fromDate: this.globalDateRange.fromDate,
        toDate: this.globalDateRange.toDate,
      })
        .then(() => this.updateChart(this.getOrderedDetails(this.orderedProductsDetails)))
        .then(() => this.stopLoading())
    },

    getOrderedDetails(orderDetails) {
      const groupOrders = []
      const orderedProducts = orderDetails || []

      orderedProducts.forEach(order => {
        const prodItems = order.data.product_items || []
        const custAccNo = order.data.c_customerAccountNo ? JSON.parse(order.data.c_customerAccountNo).value : ''
        prodItems.forEach((prodItem) => {
          groupOrders.push({
            senderProfile: order.data.c_senderProfile,
            orderNo: order.data.order_no,
            orderDate: this.formatUTCtoLocal(order.data.creation_date),
            reqShipDate: order.data.shipments[0].c_req_ship_date ? order.data.shipments[0].c_req_ship_date : '',
            shipDate: order.data.c_shippedDate ? this.formatUTCtoLocal(order.data.c_shippedDate) : '',
            email: order.data.customer_info.email,
            senderName: order.data.customer_info.customer_name,
            supervisorId: order.data.c_supervisorID,
            employeeId: order.data.c_employeeID,
            location: order.data.c_location,
            rFirstName: order.data.shipments[0].shipping_address.first_name,
            rLastName: order.data.shipments[0].shipping_address.last_name,
            custAccNo: custAccNo,
            catName: prodItem.c_productCategory,
            prodName: prodItem.product_name,
            sentiment: prodItem.c_sentiment,
            message: prodItem.c_hbc_personalizationMessage,
            signature: prodItem.c_signature,
            rAddress1: order.data.shipments[0].shipping_address.address1,
            rAddress2: order.data.shipments[0].shipping_address.address2,
            rCity: order.data.shipments[0].shipping_address.city,
            rState: order.data.shipments[0].shipping_address.state_code,
            rZip: order.data.shipments[0].shipping_address.postal_code,
          })
        })
      })

      // Sorting based on user name
      groupOrders.sort((a, b) => (a.userName > b.userName) ? 1 : -1)

      return groupOrders
    },

    formatUTCtoLocal(utc) {
      return (new Date(utc))?.toLocaleDateString()
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
